import React, { useEffect, useState } from 'react'
// import { imageURL } from '../../config'
import './Splash.scss';

export default function Splash(props) {

	const [show, setShow] = useState(false)

	useEffect(() => {
		// side effects
		let timeout = setTimeout(() => setShow(true), 1000)
	
		// cleanup
		return () => {
			clearTimeout(timeout)
		}
	}, [])

	return (
		<main className="splash">
			{show && (
				<svg width="1168" height="708" viewBox="0 0 1168 708" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="Group 43">
					<path id="wheel_1" d="M102.643 304.762C138.291 278.511 182.334 263 230 263C348.741 263 445 359.259 445 478C445 596.741 348.741 693 230 693C111.259 693 15 596.741 15 478C15 427.938 32.1106 381.871 60.8026 345.33" stroke="#C4C4C4" strokeWidth="30"/>
					<path id="wheel_2" d="M1152.7 468.543C1152.38 512.798 1138.42 557.761 1109.88 596.785C1038.78 694.001 904.055 717.551 808.961 649.387C713.866 581.222 694.414 447.157 765.515 349.942C836.615 252.726 971.343 229.177 1066.43 297.34C1106.53 326.079 1133.18 366.532 1145.26 410.999" stroke="#C4C4C4" strokeWidth="30"/>
					<g id="Frame">
					<path id="Rectangle 51" d="M210 468H612V501H210V468Z" fill="#5F5F5F"/>
					<path id="Rectangle 54" d="M412 126H778.581V158.633H412V126Z" fill="#5F5F5F"/>
					<path id="Rectangle 59" d="M721.591 18.1096L969.945 484.654L941.138 499.989L692.785 33.4439L721.591 18.1096Z" fill="#5F5F5F"/>
					<path id="Rectangle 60" d="M367.162 17.8453L615.757 484.844L586.951 500.178L338.355 33.1796L367.162 17.8453Z" fill="#5F5F5F"/>
					<path id="Rectangle 57" d="M584.918 485.698L768 141.805L794.003 155.649L610.921 499.542L584.918 485.698Z" fill="#5F5F5F"/>
					<path id="Rectangle 58" d="M207 477.383L398.329 118L427.134 133.336L235.806 492.718L207 477.383Z" fill="#5F5F5F"/>
					<path id="Rectangle 53" d="M692 0H882V33H692V0Z" fill="#5F5F5F"/>
					<path id="Rectangle 61" d="M254 1H444V34H254V1Z" fill="#5F5F5F"/>
					</g>
					</g>
				</svg>
			)}
		</main>
	)
}
