import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Splash from './Components/SplashLoader/Splash';
import ScrollToTop from './ScrollToTop';
const TShirts = lazy(() => import('./TShirts'));
const Socks = lazy(() => import('./Socks'));
const Faq = lazy(() => import('./faq'));
const Bibs = lazy(() => import('./Bibs'));
const Jerseys = lazy(() => import('./Jerseys'));
const OurStory = lazy(() => import('./OurStory'));
const LandingPage = lazy(() => import('./LandingPage'));
const SizingGuide = lazy(() => import('./SizingGuide'));
const Header = lazy(() => import('./Views/Nav/Header'));
const Footer = lazy(() => import('./Views/Footer/Footer'));
const UnityJersey = lazy(() => import('./Views/Products/UnityJersey/UnityJersey'));
const UnityJersey2 = lazy(() => import('./Views/Products/UnityJersey2.0/UnityJersey2.0'));
const MotionClassicJersey = lazy(() =>
    import('./Views/Products/MotionClassicJersey/MotionClassicJersey')
);
const ForestGreenJersey = lazy(() =>
    import('./Views/Products/ForestGreenJersey/ForestGreenJersey')
);
const SupersonicJersey = lazy(() =>
    import('./Views/Products/SupersonicJersey/SupersonicJersey')
);
const SplashJersey = lazy(() => import('./Views/Products/SplashJersey/SplashJersey'));

const MotionXAbcSocks = lazy(() =>
    import('./Views/Products/MotionXAbcSocks/MotionXAbcSocks')
);
const MotionCottonTeeBlack = lazy(() =>
    import('./Views/Products/MotionCottonTee/MotionCottonTeeBlack')
);
const MotionCottonTeeWhite = lazy(() =>
    import('./Views/Products/MotionCottonTee/MotionCottonTeeWhite')
);
const SupersonicBib = lazy(() => import('./Views/Products/SupersonicBib/SupersonicBib'));
const ClassicBib = lazy(() => import('./Views/Products/ClassicBib/ClassicBib'));
const ClassicProBib = lazy(() => import('./Views/Products/ClassicProBib/ClassicProBib'));
const ClassicProBib34 = lazy(() =>
    import('./Views/Products/ClassicProBib-3-4/ClassicProBib-3-4')
);

export default function App() {
    return (
        <Router>
            <Suspense fallback={<Splash />}>
                <ScrollToTop />
                <div className='app'>
                    <Header />
                    <Switch>
                        <Route exact path='/faq' component={Faq} />
                        <Route exact path='/bibs' component={Bibs} />
                        <Route exact path='/socks' component={Socks} />
                        <Route exact path='/tshirts' component={TShirts} />
                        <Route exact path='/' component={LandingPage} />
                        <Route exact path='/jerseys' component={Jerseys} />
                        <Route exact path='/our-story' component={OurStory} />
                        <Route exact path='/sizing-guide' component={SizingGuide} />
                        <Route
                            exact
                            path='/product/unity-jersey'
                            component={UnityJersey}
                        />
                        <Route
                            exact
                            path='/product/unity-jersey-2.0'
                            component={UnityJersey2}
                        />
                        <Route
                            exact
                            path='/product/motion-classic-jersey'
                            component={MotionClassicJersey}
                        />
                        <Route
                            exact
                            path='/product/forest-green-jersey'
                            component={ForestGreenJersey}
                        />
                        <Route
                            exact
                            path='/product/supersonic-jersey'
                            component={SupersonicJersey}
                        />
                        <Route
                            exact
                            path='/product/splash-jersey'
                            component={SplashJersey}
                        />
                        <Route
                            exact
                            path='/product/motion-x-abc-socks'
                            component={MotionXAbcSocks}
                        />
                        <Route
                            exact
                            path='/product/motion-cotton-tee-black'
                            component={MotionCottonTeeBlack}
                        />
                        <Route
                            exact
                            path='/product/motion-cotton-tee-white'
                            component={MotionCottonTeeWhite}
                        />
                        <Route
                            exact
                            path='/product/supersonic-bib'
                            component={SupersonicBib}
                        />
                        <Route exact path='/product/classic-bib' component={ClassicBib} />
                        <Route
                            exact
                            path='/product/classic-pro-bib'
                            component={ClassicProBib}
                        />
                        <Route
                            exact
                            path='/product/3-4-classic-pro-bib'
                            component={ClassicProBib34}
                        />
                        <Redirect to='/' />
                    </Switch>
                    <Footer />
                </div>
            </Suspense>
        </Router>
    );
}
